<template>
    <div id="app">
        <div v-if="$auth.ready()">
            <router-view></router-view>
            <notifications-component></notifications-component>
        </div>
    </div>
</template>

<!-- Components -->
<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-flatpickr-component/vue-flatpickr-component.scss" lang="scss"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/vendor/libs/vue-multiselect/vue-multiselect.scss" lang="scss"></style>
<style src="@/vendor/libs/vue-data-tables/vue-data-tables.scss" lang="scss"></style>
<style src="@/vendor/libs/spinkit/spinkit.scss" lang="scss"></style>

<!-- Theme -->
<style src="@/vendor/styles/appwork.scss" lang="scss"></style>
<style src="@/vendor/styles/bootstrap.scss" lang="scss"></style>
<style src="@/vendor/styles/theme-corporate.scss" lang="scss"></style>
<style src="@/vendor/styles/colors.scss" lang="scss"></style>
<style src="@/vendor/styles/uikit.scss" lang="scss"></style>
<style src="./style.scss" lang="scss"></style>

<script>

import Notifications from '@/components/partials/Notifications'

export default {
    name: 'app',

    metaInfo: {
        title: 'Index',
        titleTemplate: '%s - ' + process.env.VUE_APP_TITLE
    },

    components: {
        'notifications-component': Notifications
    },

    data () {
        return {
            message: '',

            context: 'App context',
            loaded: false
        }
    },

    created () {
        this.$auth.ready(function () {
            console.log('Ready ' + this.context)
        })
    },

    mounted () {
        var _this = this
        // Set up $auth.ready with other arbitrary loaders (ex: language file).
        setTimeout(function () {
            _this.loaded = true
        }, 500)
    },

    updated () {
        // Remove loading state
        setTimeout(() => document.body.classList.remove('app-loading'), 1)
    }
}
</script>
