import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import globals from '@/globals'

// Layouts
import Layout1 from '@/layout/Layout1'

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
    base: '/',
    mode: 'history',
    routes: [
        {
            name: 'login',
            path: '/',
            component: () => import('@/components/auth/LoginPage'),
            meta: {
                auth: false
            }
        },
        {
            name: 'logout',
            path: '/logout',
            component: () => import('@/components/auth/LogoutComponent'),
            meta: {
                auth: true
            }
        },
        {
            path: '/reset-password',
            name: 'reset-password',
            component: () => import('@/components/auth/ForgotPassword'),
            meta: {
                auth: false
            }
        },
        {
            path: '/reset-password/:token/:email',
            name: 'reset-password-form',
            component: () => import('@/components/auth/ResetPasswordForm'),
            meta: {
                auth: false
            }
        },
        {
            path: '/',
            component: Layout1,
            children: [{
                // **********************************************************************
                // ACCOUNT
                // **********************************************************************
                name: 'account-settings',
                path: 'account/settings',
                component: () => import('@/components/account/SettingsPage'),
                meta: {
                    title: 'Configuración',
                    auth: true
                }
            },
            {
                // **********************************************************************
                // ACCOUNT
                // **********************************************************************
                name: 'account-register',
                path: '/register/:secret',
                component: () => import('@/components/account/RegisterPage'),
                props: true,
                meta: {
                    title: 'Configuración',
                    auth: false
                }
            },
            {
                name: 'dashboard',
                path: 'dashboard',
                component: () => import('@/components/Home'),
                meta: {
                    title: 'Inicio',
                    auth: {
                        roles: 'paciente_all'
                    }
                }
            },
            {
                name: 'procedimiento-view-temporal',
                path: '/procedimiento/:token',
                component: () => import('@/components/procedimientos/ViewTemporaryPage'),
                props: true,
                meta: {
                    title: 'Ver Procedimiento'
                }
            },
            {
                name: 'procedimiento-view',
                path: 'procedimientos/view/:resource_id',
                component: () => import('@/components/procedimientos/ViewPage'),
                props: true,
                meta: {
                    title: 'Ver Procedimiento',
                    auth: {
                        roles: 'paciente_all'
                    }
                }
            },

            // **********************************************************************
            // ERRORS
            // **********************************************************************
            {
                name: 'not-authorized',
                path: 'not-authorized',
                component: () => import('@/components/errors/NotAuthorizedComponent'),
                meta: {
                    title: 'Error 403',
                    auth: true
                }
            },
            {
                name: 'not-found',
                path: '*',
                component: () => import('@/components/errors/NotFoundComponent'),
                meta: {
                    title: 'Error 404',
                    auth: true
                }
            },
            {
                name: 'internal-error',
                path: '*',
                component: () => import('@/components/errors/InternalErrorComponent'),
                meta: {
                    title: 'Error 500',
                    auth: true
                }
            }]
        }
    ]
})

router.afterEach(() => {
    // On small screens collapse sidenav
    if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
        setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
    }

    // Scroll to top of the page
    globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
    // Set loading state
    document.body.classList.add('app-loading')

    document.title = to.meta.title ? to.meta.title + ' - ' + process.env.VUE_APP_TITLE : process.env.VUE_APP_TITLE
    if (!to.matched.length) {
        setTimeout(() => next({ name: 'not-found' }), 10)
    }

    // Add tiny timeout to finish page transition
    setTimeout(() => next(), 10)
})

router.beforeResolve((to, from, next) => {
    // If user-settings then restrict by own user
    // TODO: improve for any route
    if (to.meta.auth?.owned && Vue.auth.user().id !== to.params.user_id) {
        setTimeout(() => next({ name: 'not-authorized' }), 10)
    }

    setTimeout(() => next(), 10)
})

export default router
